import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaHeart } from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Paintball club SplatterZone
			</title>
			<meta name={"description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:title"} content={"Paintball club SplatterZone"} />
			<meta property={"og:description"} content={"Experience the thrill, conquer the field - SplatterZone, where paintball adventures begin"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="text1">
				SplatterZone
			</Override>
			<Override slot="quarklycommunityKitMobileSidePanel" onloadShow={false} />
		</Components.Header>
		<Components.Hero background="linear-gradient(320deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.76) 72.4%),--color-darkL2 url(https://uploads.quarkly.io/65e71741926d910020edb149/images/3-3.jpg?v=2024-03-06T10:22:29.442Z) center/cover">
			<Override slot="text1">
				SplatterZone, where paintball adventures begin
			</Override>
			<Override slot="text">
				Experience the thrill, conquer the field
			</Override>
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://uploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10:22:29.445Z"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
					srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10:22:29.453Z"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
					srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-1%20%281%29.jpg?v=2024-03-06T10%3A22%3A29.453Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Welcome to unforgettable paintball experiences
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--dark"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Step onto our arena and prepare for adrenaline-pumping action, strategic showdowns, and unforgettable battles.
					<br />
					At SplatterZone, we don't just play paintball – we live it.
					<br />
					<br />
					SplatterZone offers a variety of game modes and scenarios, ensuring every visit is a new exciting adventure. Whether you're a seasoned pro or new to the game, a battle awaits.
				</Text>
				<Link
					href="#"
					color="--dark"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
				>
					Services
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Cards-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 70px 0px"
				font="--headline2"
				text-align="center"
				md-margin="0px 0px 40px 0px"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
			>
				Explore the features of SplatterZone
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 60px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="46px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="50px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10:22:29.450Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-1.jpg?v=2024-03-06T10%3A22%3A29.450Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#dcfc96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="--headline3">
							Exciting Battlefields
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Immerse yourself in our professionally designed arenas, each with its unique layout and challenges, ensuring every game is an unforgettable event.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10:22:29.434Z"
						display="block"
						margin="0px 0px 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						object-position="left"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/4-2.jpg?v=2024-03-06T10%3A22%3A29.434Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#fced96"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="--headline3">
							State-of-the-Art Equipment
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Arm yourself with our top-notch markers, masks, and protective gear, so you are battle-ready with comfort and style.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					margin="80px 0px 0px 0px"
					md-margin="0 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10:22:29.445Z"
						display="block"
						margin="0px 0\\n\\npx 45px 0px"
						width="100%"
						height="500px"
						object-fit="cover"
						lg-height="400px"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-3.jpg?v=2024-03-06T10%3A22%3A29.445Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box min-width="10px" min-height="10px" display="flex" margin="0px 0px 35px 0px">
						<Box
							min-width="10px"
							min-height="10px"
							width="55px"
							height="30px"
							background="#c5fcff"
							margin="10px 0px 0px 0px"
						/>
						<Text margin="0px 0px 0 -50px" font="--headline3">
							Professional Staff
						</Text>
					</Box>
					<Text margin="0px 0px 0 0px" font="--base" color="--dark">
						Our experienced referees and staff are dedicated to ensuring safety and fun for all players from start to finish.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10:22:29.432Z"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/3-1.jpg?v=2024-03-06T10%3A22%3A29.432Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--dark"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Whether you are planning a birthday party, corporate event, or just a day out with friends, SplatterZone offers customizable packages to suit your needs.
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://uploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10:22:29.476Z"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
						srcSet="https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71741926d910020edb149/images/1-2.jpg?v=2024-03-06T10%3A22%3A29.476Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="#dcfc96"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="fa"
							icon={FaHeart}
							color="#dcfc96"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Dynamic Battlefields
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Explore meticulously designed fields, each offering unique challenges and opportunities for tactical mastery.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="#fced96"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="io"
							icon={IoIosHeart}
							color="#fced96"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Safety First
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Our experienced staff ensures that safety remains a top priority, allowing players to focus on the game without worries.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="#c5fcff"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="fa"
							icon={FaHeart}
							color="#c5fcff"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Community Spirit
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Join a diverse community of players who come together to celebrate the thrill of paintball and build lasting friendships.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde6288e2e8e002178b6f4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});